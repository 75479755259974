import React from "react";
import { TextInput } from "react-admin";
import { useFormState, useForm } from "react-final-form";

const EventNameInput = (props) => {
  const { values: { name = "" } = {} } = useFormState();
  const { change } = useForm();
  React.useEffect(() => {
    const c = [].concat.apply(
      [],
      name
        .replace(/[^\w\s]/gi, "")
        .split(" ")
        .filter((i) => i)
        .map((i) =>
          i.split("").map((e, index) => i.substring(0, index + 1).toLowerCase())
        )
    );
    change("searchKeywords", [...new Set(c)]);
  }, [name, change]);
  return <TextInput {...props} />;
};

export default EventNameInput;
