import React from "react";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import {
  Button,
  useGetMany,
  useRefresh,
  useNotify,
  useUnselectAll,
} from "react-admin";
import { updateWinners } from "../utils/updateWinner";

const UpdateWinnerButton = ({ selectedIds, label }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const [loading, setloading] = React.useState(false);
  const { data } = useGetMany("events", selectedIds);

  const onUpdate = () => {
    setloading(true);
    updateWinners(data).then(() => {
      setloading(false);
      notify(`Updated Winners`);
      setTimeout(() => {
        unselectAll("events");
        refresh();
      }, 1000);
    });
  };
  return (
    <Button disabled={loading} onClick={onUpdate} label={label}>
      <RotateLeftIcon />
    </Button>
  );
};

export default UpdateWinnerButton;
