import * as React from "react";
import {
  TextField,
  FunctionField,
  NumberField,
  Edit,
  SimpleForm,
  BooleanInput,
  ReferenceManyField,
  Datagrid,
  ReferenceField,
} from "react-admin";
import { getEventStatus } from "../utils/common";
import Layout from "./Layout";
import WatchButton from "./WatchButton";
import DeleteCommentButton from "./DeleteCommentButton";

const PostTitle = ({ record }) => {
  return <span>{record ? `"${record.postName}"` : ""}</span>;
};

const redirect = (basePath, id, data) => `/events/${data.eventId}/show`;

export const PostEdit = (props) => (
  <Layout>
    <Edit {...props} title={<PostTitle />}>
      <SimpleForm variant="outlined" redirect={redirect}>
        <TextField source="postName" label="Event Name" />
        <FunctionField
          label="Post Status"
          render={(record) => getEventStatus(record)}
        />

        <ReferenceField
          label="Post User"
          source="userId"
          reference="users"
          link="show"
        >
          <TextField source="fullName" />
        </ReferenceField>
        <NumberField source="likeCount" />
        <NumberField source="commentCount" />
        <BooleanInput source="disablePost" />
        <WatchButton source="videoURL" label="Watch Post" isText />
        <ReferenceManyField
          label="Reported Comments"
          reference="reportedComments"
          target="postId"
        >
          <Datagrid>
            <ReferenceField
              label="Reported By"
              source="userId"
              reference="users"
              link="show"
            >
              <TextField source="fullName" />
            </ReferenceField>
            <TextField source="reason" />
            <TextField source="comment" />
            <DeleteCommentButton />
          </Datagrid>
        </ReferenceManyField>
      </SimpleForm>
    </Edit>
  </Layout>
);
