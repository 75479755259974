import * as React from "react";
import { Fragment } from "react";
import {
  Datagrid,
  List,
  Show,
  Create,
  Edit,
  Filter,
  DateField,
  SimpleShowLayout,
  SimpleForm,
  TextField,
  TextInput,
  EditButton,
  ReferenceField,
  SelectInput,
  ReferenceInput,
  RadioButtonGroupInput,
  ArrayInput,
  SimpleFormIterator,
  FunctionField,
  ArrayField,
  NumberField,
  NumberInput,
  DateTimeInput,
  ReferenceManyField,
  BooleanField,
  ShowButton,
  ImageInput,
  ImageField,
} from "react-admin";
import Layout from "./Layout";
import { Layout2 } from "./Layout";
import { getEventStatus } from "./../utils/common";
import WatchButton from "./WatchButton";
import UpdateWinnerButton from "./UpdateWinnerButton";
import EventNameInput from "./EventNameInput";
// import EditEventDateTime from "./EditEventDateTime";
import UpdatePostsFromButton from "./UpdatePostsButton";

const validateEvent = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = ["Event name is required"];
  }
  if (!values.description) {
    errors.description = ["Description is required"];
  }
  if (!values.enrollmentLimit) {
    errors.enrollmentLimit = ["Enrollment Limit is required"];
  }
  if (!values.prize) {
    errors.prize = ["Prize is required"];
  }
  if (!values.eventPhase1) {
    errors.eventPhase1 = ["Enroll Start Time is required"];
  }
  if (!values.eventPhase2) {
    errors.eventPhase2 = ["Enroll End/Posting Start Time is required"];
  }
  if (!values.eventPhase3) {
    errors.eventPhase3 = ["Posting End/Event Start Time is required"];
  }
  if (!values.eventPhase4) {
    errors.eventPhase4 = ["Event End Date is required"];
  }
  if (!values.rules) {
    errors.rules = ["Rules are required"];
  }
  if (!values.talent) {
    errors.talent = ["Talent is required"];
  }
  if (!values.poster) {
    errors.poster = ["Event Poster required"];
  }
  return errors;
};

const EventTitle = ({ record }) => {
  return <span>Event {record ? `"${record.name}"` : ""}</span>;
};

const EventFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search Event" source="name" alwaysOn />
  </Filter>
);

const createTransform = (data) => {
  return {
    ...data,
    isWinnersUpdated: false,
    enrollments: [],
  };
};

export const EventCreate = (props) => (
  <Layout>
    <Create {...props} transform={createTransform}>
      <SimpleForm validate={validateEvent} variant="outlined" redirect="show">
        <EventNameInput source="name" label="Event Name" resettable fullWidth />
        <TextInput source="description" multiline fullWidth />
        <NumberInput source="enrollmentLimit" />
        <DateTimeInput
          source="eventPhase1"
          label="Enroll Start Time"
          fullWidth
        />
        <DateTimeInput
          source="eventPhase2"
          label="Enroll End/Posting Start Time"
          fullWidth
        />
        <DateTimeInput
          source="eventPhase3"
          label="Posting End/Event Start Time"
          fullWidth
        />
        <DateTimeInput source="eventPhase4" label="Event End Date" fullWidth />
        <ImageInput source="poster" label="Event poster" accept="image/*">
          <ImageField source="src" title="title" />
        </ImageInput>
        <RadioButtonGroupInput
          label="Event Status"
          source="isEventLive"
          optionText="name"
          optionValue="value"
          choices={[
            { name: "Live", value: true },
            { name: "Offline", value: false },
          ]}
          fullWidth
        />
        <ReferenceInput
          label="Talent"
          source="talent"
          reference="talents"
          filterToQuery={(searchText) => ({ name: searchText })}
          fullWidth
        >
          <SelectInput optionText="name" fullWidth />
        </ReferenceInput>
        <ArrayInput source="prize" label="Set Prize">
          <SimpleFormIterator>
            <SelectInput
              label="Select Postion"
              source="position"
              choices={[
                { id: "first", name: "First Prize" },
                { id: "second", name: "Second Prize" },
                { id: "third", name: "Third Prize" },
                // { id: "fourth", name: "Fourth Prize" },
                // { id: "fifth", name: "Fifth Prize" },
              ]}
              fullWidth
            />
            <NumberInput label="Prize Ammout" source="value" fullWidth />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="rules" label="Set Rules">
          <SimpleFormIterator>
            <TextInput
              label="Rule Description"
              source="description"
              fullWidth
            />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  </Layout>
);

const EventBulkActionButtons = (props) => (
  <Fragment>
    <UpdateWinnerButton label="Update Winners" {...props} />
  </Fragment>
);

export const EventList = (props) => (
  <List
    {...props}
    filters={<EventFilter />}
    bulkActionButtons={<EventBulkActionButtons />}
  >
    <Datagrid
      rowClick="show"
      isRowSelectable={(record) =>
        getEventStatus(record) === "DONE" && !record.isWinnersUpdated
      }
    >
      <TextField source="name" />
      <FunctionField
        source="isEventLive"
        label="Status"
        render={(record) => `${record.isEventLive ? "Live" : "Offline"}`}
        textAlign="center"
      />
      <FunctionField
        label="Event Status"
        render={(record) => getEventStatus(record)}
        textAlign="center"
      />
      <FunctionField
        label="Winners Update"
        render={(record) => (!record.isWinnersUpdated ? "NOT UPDATED" : "DONE")}
        textAlign="center"
      />
      <FunctionField
        label="User Enrolled"
        render={(data) => `${data.enrollments.length}`}
      />
      <TextField
        source="enrollmentLimit"
        label="Enrollment Limit"
        textAlign="center"
      />
      <EditButton />
    </Datagrid>
  </List>
);

export const EventShow = (props) => (
  <Layout2>
    <Show {...props} title={<EventTitle />}>
      <SimpleShowLayout>
        <TextField source="name" />
        <ReferenceField label="Talent" source="talent" reference="talents">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="description" />
        <NumberField source="enrollmentLimit" />
        <DateField
          source="eventPhase1"
          label="Enroll Start Time"
          locales="en-IN"
          showTime
        />
        <DateField
          source="eventPhase2"
          label="Enroll End/Posting Start Time"
          locales="en-IN"
          showTime
        />
        <DateField
          source="eventPhase3"
          label="Posting End/Event Start Time"
          locales="en-IN"
          showTime
        />
        <DateField
          source="eventPhase4"
          label="Event End Date"
          locales="en-IN"
          showTime
        />
        <ImageField source="poster.src" label="Event poster" />
        <FunctionField
          label="Live Status"
          render={(status) => `${status.isEventLive ? "Live" : "Offline"}`}
        />
        <ArrayField source="enrollments" label="Registration">
          <Datagrid>
            <ReferenceField
              label="Registered Users"
              source="userId"
              reference="users"
              link="show"
            >
              <TextField source="fullName" />
            </ReferenceField>
          </Datagrid>
        </ArrayField>
        <ArrayField source="prize" label="Prizes">
          <Datagrid>
            <TextField source="value" />
            <TextField source="position" label="Prize" />
          </Datagrid>
        </ArrayField>
        <ArrayField source="rules" label="Rules">
          <Datagrid>
            <TextField source="description" />
          </Datagrid>
        </ArrayField>
        <FunctionField
          label="Event Status"
          render={(record) => getEventStatus(record)}
        />
        <ReferenceField
          label="First Place"
          source="winners.first.userId"
          reference="users"
          link="show"
        >
          <TextField source="fullName" />
        </ReferenceField>
        <ReferenceField
          label="Second Place"
          source="winners.second.userId"
          reference="users"
          link="show"
        >
          <TextField source="fullName" />
        </ReferenceField>
        <ReferenceField
          label="Third Place"
          source="winners.third.userId"
          reference="users"
          link="show"
        >
          <TextField source="fullName" />
        </ReferenceField>
        <ReferenceManyField
          label="Reported Posts"
          reference="reportedPosts"
          target="eventId"
        >
          <Datagrid>
            {/* <ReferenceField
              label="Reported By"
              source="userId"
              reference="users"
              link="show"
            >
              <TextField source="fullName" />
            </ReferenceField> */}
            <ReferenceField label="Posted By" source="postId" reference="posts">
              <ReferenceField
                label="User"
                source="userId"
                reference="users"
                link={(record, reference) => `/posts/${record.id}`}
              >
                <TextField source="fullName" />
              </ReferenceField>
            </ReferenceField>
            <TextField source="reason" />
          </Datagrid>
        </ReferenceManyField>
        <ReferenceManyField label="Posts" reference="posts" target="eventId">
          <Datagrid>
            <ReferenceField
              label="User"
              source="userId"
              reference="users"
              link="show"
            >
              <TextField source="fullName" />
            </ReferenceField>
            <BooleanField
              source="disablePost"
              label="Is Disabled"
              textAlign="center"
            />
            <NumberField source="likeCount" label="Likes" textAlign="center" />
            <NumberField
              source="commentCount"
              label="Comments"
              textAlign="center"
            />
            <DateField
              source="createdate"
              label="Posted On"
              sortBy="createdate"
              options={{
                weekday: "short",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
                hour12: true,
              }}
            />
            <EditButton label="" textAlign="center" />
            <ShowButton label="" textAlign="center" />
            <WatchButton source="videoURL" label="" textAlign="center" />
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  </Layout2>
);

export const EventEdit = (props) => {
  return (
    <Layout>
      <Edit {...props} title={<EventTitle />}>
        <SimpleForm validate={validateEvent} variant="outlined" redirect="show">
          <EventNameInput
            source="name"
            label="Event Name"
            resettable
            fullWidth
          />
          <TextInput source="description" multiline fullWidth />
          <NumberInput source="enrollmentLimit" />
          <DateTimeInput
            source={"eventPhase1"}
            locales="en-IN"
            showTime
            variant="outlined"
            fullWidth
            label="Enroll Start Time"
          />
          <DateTimeInput
            source={"eventPhase2"}
            locales="en-IN"
            label="Enroll End/Posting Start Time"
            showTime
            variant="outlined"
            fullWidth
          />
          <DateTimeInput
            source={"eventPhase3"}
            locales="en-IN"
            label="Posting End/Event Start Time"
            showTime
            variant="outlined"
            fullWidth
          />
          <DateTimeInput
            source={"eventPhase4"}
            locales="en-IN"
            label="Event End Date"
            showTime
            variant="outlined"
            fullWidth
          />
          <UpdatePostsFromButton label="Refresh All Posts Data" />
          <ImageInput source="poster" label="Event poster" accept="image/*">
            <ImageField source="src" title="title" />
          </ImageInput>
          <RadioButtonGroupInput
            label="Event Status"
            source="isEventLive"
            optionText="name"
            optionValue="value"
            choices={[
              { name: "Live", value: true },
              { name: "Offline", value: false },
            ]}
            fullWidth
          />
          <ReferenceInput
            label="Talent"
            source="talent"
            reference="talents"
            filterToQuery={(searchText) => ({ name: searchText })}
            fullWidth
          >
            <SelectInput optionText="name" fullWidth />
          </ReferenceInput>
          <ArrayInput source="prize" label="Set Prize">
            <SimpleFormIterator>
              <SelectInput
                label="Select Postion"
                source="position"
                choices={[
                  { id: "first", name: "First Prize" },
                  { id: "second", name: "Second Prize" },
                  { id: "third", name: "Third Prize" },
                  // { id: "fourth", name: "Fourth Prize" },
                  // { id: "fifth", name: "Fifth Prize" },
                ]}
                fullWidth
              />
              <NumberInput label="Prize Ammout" source="value" fullWidth />
            </SimpleFormIterator>
          </ArrayInput>
          <ArrayInput source="rules" label="Set Rules">
            <SimpleFormIterator>
              <TextInput
                label="Rule Description"
                source="description"
                fullWidth
              />
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleForm>
      </Edit>
    </Layout>
  );
};
