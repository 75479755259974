import * as React from "react";
import {
  Datagrid,
  List,
  Create,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";
import Layout from "./Layout";
export const TalentList = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField source="name" label="Talents" />
    </Datagrid>
  </List>
);

export const TalentCreate = (props) => (
  <Layout>
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" fullWidth />
      </SimpleForm>
    </Create>
  </Layout>
);
