import * as React from "react";
import get from "lodash/get";
import { Button } from "react-admin";
import { Button as MButton } from "@material-ui/core";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";

const WatchButton = ({ source, record = {}, isText }) => {
  return (
    <>
      {isText ? (
        <MButton
          variant="outlined"
          color="primary"
          onClick={() => window.open(get(record, source), "_blank")}>
          {`Watch Post  `}
          <PlayArrowIcon />
        </MButton>
      ) : (
        <Button onClick={() => window.open(get(record, source), "_blank")}>
          <PlayArrowIcon />
        </Button>
      )}
    </>
  );
};

export default WatchButton;
