import moment from "moment";

export const getEventStatus = (record) => {
  const { eventPhase1, eventPhase2, eventPhase3, eventPhase4 } = record;
  switch (true) {
    case moment(eventPhase1).diff(moment(), "millisecond") >= 0:
      return "UPCOMING";
    case moment(eventPhase1).diff(moment(), "millisecond") < 0 &&
      moment(eventPhase2).diff(moment(), "millisecond") >= 0:
      return "ENROLL";
    case moment(eventPhase2).diff(moment(), "millisecond") < 0 &&
      moment(eventPhase3).diff(moment(), "millisecond") >= 0:
      return "POSTING";
    case moment(eventPhase3).diff(moment(), "millisecond") < 0 &&
      moment(eventPhase4).diff(moment(), "millisecond") >= 0:
      return "ONGOING";
    case moment(eventPhase4).diff(moment(), "millisecond") <= 0:
      return "DONE";
    default:
      return "DONE";
  }
};

export const canUpdateEventTimes = (eventPhase2) => {
  const canEdit = moment(eventPhase2).diff(moment(), "millisecond") > 0;
  return canEdit;
};
