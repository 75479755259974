import * as React from "react";
import {
  Show,
  DateField,
  SimpleShowLayout,
  TextField,
  ArrayField,
  Datagrid,
  List,
  SingleFieldList,
  ChipField,
  ReferenceManyField,
  FunctionField,
  ReferenceField,
} from "react-admin";
import Layout from "./Layout";
import { Button } from "react-admin";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";

const UserTitle = ({ record }) => {
  return <span>{record ? `${record.fullName}` : ""}</span>;
};

export const UserShow = (props) => (
  <Layout>
    <Show {...props} title={<UserTitle />}>
      <SimpleShowLayout>
        <TextField source="fullName" />
        <TextField source="email" />
        <TextField source="mobile" />
        <DateField source="dob" />
        <DateField source="createdate" label="Created On" showTime />
        <DateField source="lastUpdate" label="Updated On" showTime />
        <ArrayField source="interestedTalents" label="Interested Talents">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ArrayField>
        <ArrayField source="showcase" label="Showcase Videos">
          <Datagrid>
            <TextField source="title" />
            <FunctionField
              label="Watch Video"
              render={(data) => (
                <Button onClick={() => window.open(data.videoUrl, "_blank")}>
                  <PlayArrowIcon />
                </Button>
              )}
            />
          </Datagrid>
        </ArrayField>
        <ReferenceManyField
          label="Users Reports"
          reference="reportedUsers"
          target="reportedUserId"
        >
          <Datagrid>
            <ReferenceField
              label="Reported By"
              source="reportedByUserId"
              reference="users"
              link="show"
            >
              <TextField source="fullName" />
            </ReferenceField>
            <TextField source="reason" />
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  </Layout>
);

export const UserList = (props) => (
  <Layout>
    <List {...props}>
      <Datagrid rowClick="show" isRowSelectable={() => false}>
        <TextField source="fullName" />
        <TextField source="email" />
        <TextField source="mobile" />
        <ArrayField source="interestedTalents" label="Interested Talents">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ArrayField>
      </Datagrid>
    </List>
  </Layout>
);
