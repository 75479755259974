import React from "react";
import { Admin, Resource } from "react-admin";
import StarOutlined from "@material-ui/icons/StarOutlined";
import { dataProvider, authProvider } from "./../../utils/firebase/";
import {
  EventList,
  EventShow,
  EventCreate,
  EventEdit,
} from "../../components/Events";
import { UserShow, UserList } from "../../components/Users";
import { TalentList, TalentCreate } from "./../../components/Talents";
import { PostEdit } from "./../../components/Posts";
import checkIsAdmin from "./../../utils/checkIsAdmin";

export default function Home() {
  return (
    <Admin dataProvider={dataProvider} authProvider={authProvider}>
      {(user) =>
        checkIsAdmin(user)
          ? [
              <Resource
                name="events"
                list={EventList}
                show={EventShow}
                create={EventCreate}
                edit={EventEdit}
              />,
              <Resource
                name="talents"
                list={TalentList}
                create={TalentCreate}
                icon={StarOutlined}
              />,
              <Resource name="users" show={UserShow} list={UserList} />,
              <Resource name="posts" edit={PostEdit} />,
              <Resource name="reportedPosts" />,
              <Resource name="reportedComments" />,
              <Resource name="reportedUsers" />,
            ]
          : null
      }
    </Admin>
  );
}
