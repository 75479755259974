import { firebaseApp } from "../utils/firebase";

export const updateWinners = (data) => {
  return new Promise((resolve) => {
    const result = data.map(async (event) => {
      return await firebaseApp
        .functions()
        .httpsCallable("updateWinnerUsingEventId")(event)
        .then((data) => data.data)
        .catch((err) => err);
    });
    resolve(result);
  });
};
