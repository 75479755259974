import React from "react";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import { Button, useNotify } from "react-admin";
import updatePostsAfterEventUpdates from "../utils/updatePostsAfterEventUpdates";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";

const UpdatePostsFromButton = (props) => {
  const { record, label } = props;
  const notify = useNotify();
  const [loading, setloading] = React.useState(false);

  const onUpdate = async () => {
    setloading(true);
    const eventData = {
      ...record,
      eventPhase1: moment(record.eventPhase1).toDate().getTime(),
      eventPhase2: moment(record.eventPhase2).toDate().getTime(),
      eventPhase3: moment(record.eventPhase3).toDate().getTime(),
      eventPhase4: moment(record.eventPhase4).toDate().getTime(),
    };
    console.log(eventData);
    const resp = await updatePostsAfterEventUpdates(eventData);
    console.log(resp);
    setloading(false);
    notify("Done");
  };
  if (loading) {
    return <CircularProgress color="primary" />;
  }
  return (
    <Button disabled={loading} onClick={onUpdate} label={label}>
      <AutorenewIcon />
    </Button>
  );
};

export default UpdatePostsFromButton;
