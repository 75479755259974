import { adminEmails } from "../constants";

const checkIsAdmin = (user) => {
  if (adminEmails.find((email) => email === user.email)) {
    return true;
  } else {
    return false;
  }
};
export default checkIsAdmin;
