import React from "react";
import "./layout.css";
export default function Layout({ children }) {
  return (
    <div className="layout__main__container">
      <div className="layout__sub__container">{children}</div>
    </div>
  );
}

export function Layout2({ children }) {
  return (
    <div className="layout__main__container">
      <div className="layout__sub__container__wide">{children}</div>
    </div>
  );
}
