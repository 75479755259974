import * as React from "react";
import { Button } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { useNotify, useRefresh } from "react-admin";
import { firebaseApp } from "../utils/firebase";

const DeleteCommentButton = ({ record = {} }) => {
  const notify = useNotify();
  const refresh = useRefresh();

  const onDeleteComment = async () => {
    console.log(record);
    await firebaseApp
      .firestore()
      .doc(`posts/${record.postId}/comments/${record.commentId}`)
      .delete();
    await firebaseApp.firestore().doc(`reportedComments/${record.id}`).delete();
    notify("Comment Deleted");
    refresh();
  };
  return (
    <>
      <Button
        variant="outlined"
        onClick={onDeleteComment}
        style={{ color: "red", fontSize: ".8rem" }}
      >
        Delete Comment
        <DeleteIcon />
      </Button>
    </>
  );
};

export default DeleteCommentButton;
